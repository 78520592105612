import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ["query", "customers", "newquery", "chips", "storeContainer"]
  connect() {
    var searchFields = document.querySelectorAll('input[placeholder="Search"].search_field_customer');

    if (searchFields.length > 0) {
      searchFields.forEach((searchField) => {
        if (searchField) {
          searchField.focus();
        }
      });
    }

    var pills = document.querySelectorAll('.pill.mr-1')
    if (pills.length > 2) {
      document.querySelectorAll('.clear-search').forEach(function(element) {
        element.classList.remove('d-none');
      });
    }
    else {
      document.querySelectorAll('.clear-search').forEach(function(element) {
        if (!element.classList.contains('d-none')) {
          element.classList.add('d-none');
        }
      });
    }
  }

  removePill(event) {
    const pill = event.target.closest(".pill");
    const searchType = pill.dataset.searchType;
    const searchValue = pill.dataset.searchValue;

    pill.remove();

    const searchHistory = JSON.parse(this.element.querySelector('input[name="search_history"]').value);

    if (searchHistory[searchType]) {
      searchHistory[searchType] = searchHistory[searchType].filter(value => value !== searchValue);

      if (searchHistory[searchType].length === 0) {
        delete searchHistory[searchType];
      }
    }

    this.element.querySelector('input[name="search_history"]').value = JSON.stringify(searchHistory);
    this.element.requestSubmit();
  }

  search() {
    // Typeahead search on the customer search form
    const value = this.queryTarget.value
    Rails.ajax({
      type: "GET",
      url: `/staff/customers/search?query=${value}`,
      success: (_data, _status, xhr) => {
        this.customersTarget.innerHTML = xhr.response
      }
    })
  }

  toggleField(container) {
    const newquery = container.querySelector('[data-customer-search-target="newquery"]');
    const storeContainer = container.querySelector('[data-customer-search-target="storeContainer"]');

    newquery.style.display = 'none';
    storeContainer.style.display = 'none';

    switch (event.target.value) {
      case 'store':
        storeContainer.style.display = 'block';
        break;
      default:
        newquery.style.display = 'block';
    }
  }

  toggleSearchFields(event) {
    const container = event.target.closest('div').nextElementSibling;
    this.toggleField(container)
  }

  toggleSearchFieldsMobile(event) {
    const container = event.target.parentElement.parentElement.nextElementSibling.childNodes[1]
    this.toggleField(container)
  }

}
