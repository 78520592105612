import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const linksContainers = document.querySelectorAll(".links");

    linksContainers.forEach((linksDiv) => {
      const aboveDiv = linksDiv.previousElementSibling;

      const existingField = aboveDiv?.querySelector(".order-infor-field");

      if (!existingField) {
        const addButton = linksDiv.querySelector(".add_fields");
        if (addButton) {
          addButton.click();
        } else {
          console.error("Add fields button not found in links div:", linksDiv);
        }
      }
    });
  }
}
