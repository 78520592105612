import {Controller} from "stimulus";
import {setFormattedPrice, hide, show} from "./helpers";

export default class extends Controller {
  static targets = [ "platform", "size", "subTotal", 'scaleToOtherWrapper', "scaleTo", 'advancedWrapper', 'relativeScale',
    'productType', "ornamentWrapper", 'miniatureWrapper', 'ornamentType', 'denominator', 'calculatedSize', 'originalSubTotal']
  static values = { total: Number, priceHeight: String }

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element

    console.log('reorder connected', this.identifier)
    this.toggleSizeRadio()
    this.selectNoPlatform()
  }

  selectNoPlatform() {
    const radioButtons = document.querySelectorAll('input[name="order[order_item][platform_style_id]"]')
    const noSelection = Array.from(radioButtons).every(radio => !radio.checked)
    const noPlatformRadio = document.getElementById(Array.from(document.querySelectorAll('label')).find((label) => label.textContent.trim() === "No platform").getAttribute('for'))
    if (noSelection) {
      noPlatformRadio.checked = true
    }
  }

  validateForm(event) {
    if (!this.denominatorTarget.value && !this.isAnySizeChecked()) {
      event.preventDefault()
      alert("Relative scale can't be zero or blank");
    }
  }

  isAnySizeChecked() {
    for (let i = 0; i < this.sizeTargets.length; i++) {
      if (this.sizeTargets[i].checked) {
        return true;
      }
    }
    return false;
  }

  toggleOrnamentType(){
    console.log('ornament type change', this.ornamentTypeRadioValue())
    if(this.ornamentTypeRadioValue()) { // handle changing from miniature to ornament and back again without values getting weird
      if(this.ornamentTypeRadioValue().value === 'santa_hat' || this.ornamentTypeRadioValue().value === 'candy_cane') {
        // 4 inch
        document.getElementById('order_order_item_figure_size_inch_4').checked = true // HACK: easier than iterating through the list of radio buttons...
      } else { // hook
        // 6 inch
        document.getElementById('order_order_item_figure_size_inch_6').checked = true // HACK: easier than iterating through the list of radio buttons...
      }
      this.toggleSizeRadio()

      this.totalValue = Number(this.ornamentTypeRadioValue().dataset['price'])
      this.originaltotalValue = Number(this.ornamentTypeRadioValue().dataset['originalPrice'])
      if (this.ornamentTypeRadioValue().value == 'candy_cane'){
        this.totalValue = Number(this.ornamentTypeRadioValue().dataset['candyCanePrice'])
        this.originaltotalValue = Number(this.ornamentTypeRadioValue().dataset['candyCaneOriginalPrice'])
      } else if (this.ornamentTypeRadioValue().value == 'dog_santa_hat'){
        this.totalValue = Number(this.ornamentTypeRadioValue().dataset['petPrice'])
        this.originaltotalValue = Number(this.ornamentTypeRadioValue().dataset['petOriginalPrice'])
      }
      setFormattedPrice(this.subTotalTarget, this.totalValue)
      setFormattedPrice(this.originalSubTotalTarget, this.originaltotalValue)
      this.updateSubTotalDisplay()
    }
  }

  ornamentTypeRadioValue() {
    for(let i = 0; i < this.ornamentTypeTargets.length; i++) {
      if(this.ornamentTypeTargets[i].checked)
        return this.ornamentTypeTargets[i]
    }
  }

  toggleSizeRadio(){
    console.log('toggleSizeRadio', this.relativeScaleTarget.value)
    // clear advanced since we're doing select now
    this.relativeScaleTarget.value = ''
    this.togglePlatform()
  }

  toggleProductType() {
    console.log('product type change', this.productTypeRadioValue())
    if(this.productTypeRadioValue() === 'ornament') {
      hide(this.miniatureWrapperTarget)
      show(this.ornamentWrapperTarget)
      // HACK: easier than iterating through the list of radio buttons...
      document.getElementById(Array.from(document.querySelectorAll('label')).find(lbl => lbl.textContent.trim() === "No platform")?.htmlFor).checked = true
      if (document.querySelector('input[name="order[order_item][ornament_type]"]:checked')?.value == 'candy_cane'){
        document.getElementById('order_order_item_ornament_type_candy_cane').checked = true
      } else if (document.querySelector('input[name="order[order_item][ornament_type]"]:checked')?.value == 'dog_santa_hat') {
        document.getElementById('order_order_item_ornament_type_dog_santa_hat').checked = true
      } else if (document.querySelector('input[name="order[order_item][ornament_type]"]:checked')?.value == 'santa_hat') {
        document.getElementById('order_order_item_ornament_type_santa_hat').checked = true // set one ornament style so we don't get a validation failure
      }
      this.toggleOrnamentType()
    } else {
      hide(this.ornamentWrapperTarget)
      show(this.miniatureWrapperTarget)
      document.getElementById('order_order_item_ornament_type_ring_green').checked = false // clear for validation
      document.getElementById('order_order_item_ornament_type_ring_red').checked = false
      this.toggleSizeRadio()
    }
  }

  updateImage(event) {
    const selectedKey = event.target.value;

    let imagePath
    switch (selectedKey) {
      case 'candy_cane':
        imagePath = document.getElementById('ornamentImage').dataset.candyCaneUrl;
        break;
      case 'dog_santa_hat':
        imagePath = document.getElementById('ornamentImage').dataset.santaHatUrl;
        break;
      case 'santa_hat':
      default:
        imagePath = document.getElementById('ornamentImage').dataset.santaHatUrl;
        break;
    }

    const imageElement = document.getElementById('ornamentImage');
    if (imageElement) {
      imageElement.src = imagePath;
    }
  }

  denominatorInput() {
    console.log('denominator change', this.denominatorTarget.value, this.relativeScaleTarget.value)
    this.relativeScaleTarget.value = 1 / this.denominatorTarget.value
    this.relativeScaleInput()
  }

  productTypeRadioValue() {
    for(let i = 0; i < this.productTypeTargets.length; i++) {
      if(this.productTypeTargets[i].checked)
        return this.productTypeTargets[i].dataset.type
    }
  }

  togglePlatform() {
    console.log('togglePlatform')
    this.toggleSize()
  }

  toggleAdvanced(){
    console.log('toggle advanced')
    this.advancedWrapperTarget.classList.toggle('d-none')
  }

  relativeScaleInput(){
    console.log('relative scale changed', this.relativeScaleTarget.value, this.relativeScaleTarget.dataset.height)
    // clear any size selection since we're doing advanced now
    this.clearSizeRadio()

    let scale = null
    try{
      scale = Number(this.relativeScaleTarget.value)
    } catch (error){
      console.log('not a number yet, nothing to do')
    }

    if (scale){
      console.log('price height', Number(this.relativeScaleTarget.dataset.height) * this.relativeScaleTarget.value)
      let priceHeight = Number(this.relativeScaleTarget.dataset.height) * this.relativeScaleTarget.value
      let ph_decimal = priceHeight % 1
      let ph_value = 0
      let text = ''
      if(ph_decimal > 0.1){
        ph_value = Math.ceil(priceHeight)
      } else {
        ph_value = Math.floor(priceHeight)
      }
      const price = JSON.parse(this.relativeScaleTarget.dataset.price)[`price_${ph_value}`]
      const basePrice = JSON.parse(this.relativeScaleTarget.dataset.originalPrice)[`price_${ph_value}`]
      console.log(ph_value, this.relativeScaleTarget.dataset.price, price)

      if (price)
        text = "Miniature will have a height of " + priceHeight.toFixed(2) + ' inches'
      else
        text += "<span class='text-danger'>Print height is outside of our printable range. Please try again</span>"

      this.calculatedSizeTarget.innerHTML = text
      if(price) { // we need a relative scale that's in our pricing range or we can't set pricing
        this.priceHeight = `price_${ph_value}`
        this.totalValue = price
        this.originaltotalValue = basePrice
        this.calcPlatform()
      }
    }
  }

  toggleSize() {
    console.log('reorder toggle size', this.sizeRadioValue())

    if(this.sizeRadioValue()) {
      if (this.sizeRadioValue().value === 'scale_to_other') {
        this.scaleToOtherWrapperTarget.classList.remove('d-none')
        //this.scaleToTarget.selectedIndex = 2
        this.toggleScaleTo()
      } else {
        if (this.hasScaleToOtherWrapperTarget) {
          this.scaleToOtherWrapperTarget.classList.add('d-none')
        }

        this.totalValue = Number(this.sizeRadioValue().dataset['price'])
        this.originaltotalValue = Number(this.sizeRadioValue().dataset['originalPrice'])
      }
    }
    this.calcPlatform()
  }

  calcPlatform() {
    if (this.platformRadioValue() && this.platformRadioValue().value !== 'no_platform') {
      console.log(this.platformRadioValue(), this.totalValue)
      if (this.relativeScaleTarget.value){
        // we have a relative scale in the box - that wins
        console.log('relative scale value to lookup inside calcplatform', )

        this.totalValue += Number(JSON.parse(this.platformRadioValue().dataset['price'])[JSON.parse(this.relativeScaleTarget.dataset.platform)[this.priceHeight]])
        this.originaltotalValue += Number(JSON.parse(this.platformRadioValue().dataset['price'])[JSON.parse(this.relativeScaleTarget.dataset.platform)[this.priceHeight]])
      }
      else if (this.sizeRadioValue().value === 'scale_to_other') {
        console.log('platform calc size for other', this.scaleToTarget.selectedOptions[0].dataset['platformSize'])
        this.totalValue += Number(JSON.parse(this.platformRadioValue().dataset['price'])[this.scaleToTarget.selectedOptions[0].dataset['platformSize']])
        this.originaltotalValue += Number(JSON.parse(this.platformRadioValue().dataset['price'])[this.scaleToTarget.selectedOptions[0].dataset['platformSize']])
      }
      else {
        this.totalValue += Number(JSON.parse(this.platformRadioValue().dataset['price'])[this.sizeRadioValue().dataset['platformSize']])
        this.originaltotalValue += Number(JSON.parse(this.platformRadioValue().dataset['price'])[this.sizeRadioValue().dataset['platformSize']])
      }
    }
    setFormattedPrice(this.subTotalTarget, this.totalValue)
    setFormattedPrice(this.originalSubTotalTarget, this.originaltotalValue)
    this.updateSubTotalDisplay()
  }

  toggleScaleTo() {
    console.log('toggle scale to', this.scaleToTarget)
    this.totalValue = Number(this.scaleToTarget.selectedOptions[0].dataset['price'])
    this.originaltotalValue = Number(this.scaleToTarget.selectedOptions[0].dataset['originalPrice'])
    this.calcPlatform()
  }

  sizeRadioValue() {
    for(let i = 0; i < this.sizeTargets.length; i++) {
      if(this.sizeTargets[i].checked)
        return this.sizeTargets[i]
    }
  }

  clearSizeRadio() {
    for(let i = 0; i < this.sizeTargets.length; i++) {
     this.sizeTargets[i].checked = false
    }
  }

  platformRadioValue() {
    for(let i = 0; i < this.platformTargets.length; i++) {
      if(this.platformTargets[i].checked)
        return this.platformTargets[i]
    }
  }

  updateSubTotalDisplay() {
    if (this.sizeTarget.getAttribute("in_store_scan") == 'true') {
      this.originalSubTotalTarget.className = 'font-size-lg text-dark font-weight-medium';
      this.originalSubTotalTarget.style.textDecoration = "none";
      this.subTotalTarget.style.display = 'none';
    }
    else if (this.totalValue == this.originaltotalValue) {
      this.subTotalTarget.className = 'font-size-lg text-dark font-weight-medium';
      this.originalSubTotalTarget.style.display = 'none';
    }
    else {
      this.subTotalTarget.className = 'text-danger fw-bold mt-1';
      this.originalSubTotalTarget.style.display = 'block';
    }
  }
}
